import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import ArrowDown from '../ArrowDown'
import PrivacyToggle from './PrivacyToggle'

const Content = styled.div`
  display: block;

  h2 {
    margin-bottom: 1.5rem;
    line-height: 1.2;
  }

  h3 {
    margin-bottom: 1rem;
    font-size: 1.3em;
  }
`

const Center = styled.div`
  margin: 0 auto;
  text-align: center;
`

const Section = styled.div`
  border-bottom: 1px solid #e63b28;

  &:last-child {
    border-bottom: none;
  }
`

const SectionHead = styled.div`
  cursor: pointer;
  position: relative;
  padding-right: 30px;
`

const SectionArrow = styled.div`
  position: absolute;
  top: 1rem;
  top: calc(50% - 17px);
  right: 0;
  pointer-events: none;
  transition: transform 0.25s ease;

  ${props =>
    props.isActive &&
    css`
      transform: rotate(-180deg);
    `}
`

const SectionContent = styled.div`
  display: none;
  padding-bottom: 4rem;

  ${props =>
    props.isActive &&
    css`
      display: block;
    `}
`

function PrivacyPolicy() {
  const [sections, setSections] = useState([false, false, false])

  function toggleSection(index) {
    const newSections = [...sections]
    newSections[index] = !newSections[index]
    setSections(newSections)
  }

  return (
    <Content>
      <h2>
        Data Privacy
        <br />
        at GASSER PARTNER Attorneys-at-Law
      </h2>
      <p>
        Here at GASSER PARTNER we make protecting your personal data our highest
        priority. We have a professional duty to ensure confidentiality so we
        are committed to the utmost secrecy in every aspect of our work. GASSER
        PARTNER processes your data in accordance with the statutory provisions
        of the European General Data Protection Regulation (GDPR), including the
        latest version of any national legislation.
      </p>
      <p>
        This privacy policy, pursuant to Article 13 of the GDPR, is to inform
        you about the collection of personal data and to disclose the purposes
        for which such data is processed, and how you can exercise your rights
        under the GDPR.
      </p>
      <h3>Contact details of the data controller </h3>
      <p>
        GASSER PARTNER processes information and personal data relating to you.
        The data controller in the context of the GDPR is:
      </p>
      <Center>
        <strong>GASSER PARTNER Attorneys-at-Law</strong>
        <br />
        Feldkircher Strasse 31<br />
        Postfach 423<br />
        9494 Schaan
        <br />
        Liechtenstein
        <br />
        E-mail: datenschutz@gasserpartner.com
        <br />
        Telephone: [+423] 236 30 80
      </Center>
      <br />
      <h3>Contact details of the data protection officer </h3>
      <p>
        If you have any questions about this privacy policy or data protection
        at GASSER PARTNER Attorneys-at-Law, please contact:
      </p>
      <Center>
        <strong>GASSER PARTNER Attorneys-at-Law</strong>
        <br />
        René Saurer
        <br />
        Feldkircher Strasse 31<br />
        Postfach 423<br />
        9494 Schaan
        <br />
        Liechtenstein
        <br />
        E-mail: datenschutz@gasserpartner.com
        <br />
        Telephone: [+423] 236 30 80
      </Center>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <Section>
        <SectionHead
          className="section-head"
          onClick={() => toggleSection(0)}
          isActive={sections[0]}
        >
          <h2>
            Privacy Policy for Visitors to our Website
            <br />
            GASSER PARTNER Attorneys-at-Law
          </h2>
          <SectionArrow isActive={sections[0]}>
            <ArrowDown />
          </SectionArrow>
        </SectionHead>
        <SectionContent className="section-content" isActive={sections[0]}>
          <h3>A. General</h3>
          This Data Privacy Statement is to inform you about data processing on
          our website (www.gasserpartner.com). It will give you, as a visitor to
          our website, all the necessary information as to how, why and to what
          extent we obtain and use personal data from you. This Data Privacy
          Statement will also give you information on your rights and on data
          protection.
          <br />
          <h3>B. Collection, processing and use of personal data</h3>
          <h4>1. Which categories of personal data are collected?</h4>
          1.1 When you simply visit our website, i.e. if you do not transfer
          information to us in any other way, we might obtain the following
          data:
          <br />
          <br />
          (a) IP address;
          <br />
          <br />
          (b) Date and time of the request;
          <br />
          <br />
          (c) Time zone difference to Greenwich Mean Time (GMT);
          <br />
          <br />
          (d) Content of the request (specific page);
          <br />
          <br />
          (e) Access status/http-status code;
          <br />
          <br />
          (f) Quantity of data transferred in each case;
          <br />
          <br />
          (g) Website from which the request originates;
          <br />
          <br />
          (h) Browser;
          <br />
          <br />
          (i) Operating system and its interface; and/or
          <br />
          <br />
          (j) Language and version of the browser software.
          <br />
          <br />
          1.2 In addition to simply visiting our website, we also give you the
          opportunity to contact us via e-mail or by other electronic forms of
          communication. For this purpose, we may also collect the following
          personal information: <br />
          <br />
          (a) Name and contact details;
          <br />
          <br />
          (b) Private and/or business address and/or
          <br />
          <br />
          (c) E-mail address and telephone number.
          <br />
          <h4>2. How is that personal data collected?</h4>
          2.1 We may collect your personal data, or you may provide it to us, by
          various means, from information:
          <br />
          (a) collected when you view or access our website (see Section C
          [Cookies, Matomo and File Downloads] below); and/or
          <br />
          (b) communicated by you to us by e-mail or other forms of electronic
          communication.
          <br />
          <h4>
            3. What are the grounds for processing your personal data and how do
            we use that personal data?
          </h4>
          3.1 The processing of your personal data is in principle limited to
          the data required to provide a functional website as well as our
          content and services. <br />
          3.2 We use your personal data:
          <br />
          (a) to show you our website;
          <br />
          (b) tp ensure the stability and security of our website; and/or
          <br />
          (c) to process any e-mail inquiry from you.
          <br />
          3.3 We may process your personal data for any of the purposes set out
          above where one (or more) of the following legitimate processing
          grounds applies:
          <br />
          (a) processing is necessary to implement a contract with you;
          <br />
          (b) processing is necessary for us to comply with our legal
          obligations;
          <br />
          (c) processing is necessary for our legitimate interests unless those
          legitimate interests are overridden by your interests, fundamental
          rights or freedoms; and/or
          <br />
          (d) you have consented to the processing in question.
          <br />
          <h4>4. With whom may we share your data?</h4>
          4.1 We may share your personal data with:
          <br />
          (a) third parties who provide us with technical support, such as our
          IT providers; and/or
          <br />
          (b) Third-party suppliers who help us to design our website to make it
          more attractive and functional (see Section C below [Cookies, Matomo
          and File Downloads]).
          <br />
          <h4>
            5. Will your data be transferred to third countries or to any
            international organization?
          </h4>
          5.1 Should you visit our website or should you make contact via our
          website, your personal data might be transferred to recipients (see
          Section 4) in a third country.
          <br />
          5.2 Transfer of your personal data to recipients in a third country or
          an international organization will only take place if the following
          are ensured:
          <br />
          (a) the European Commission considers that the third country offers an
          appropriate level of protection (adequacy decision); and/or
          <br />
          (b) the recipient has signed a contract based on standard contractual
          clauses (SCC) confirmed by the European Commission obliging it to
          protect personal data. <br />
          5.3 Transfer of your personal data outside of the European Economic
          Area is made to Switzerland. The European Commission has determined a
          sufficient level of protection in an adequacy decision.
          <br />
          <h4>6. How long will we retain your personal data?</h4>
          6.1 We store your personal data for reasons of data security in order
          to ensure the stability and functional security of our system and only
          for as long as is necessary to meet the aforementioned purposes.
          <br />
          6.2 Our IT provider stores your personal data when you make contact by
          e-mail. Should we form a business or client-attorney relationship with
          you, we will process and store your personal data for the term of your
          business or attorney-client relationship, in accordance with the
          general Data Privacy Statement.
          <br />
          6.3 If errors occur on the main page, the hosting provider stores them
          for 7 days in ‘error logs’ with the date, IP address, page and type of
          error.
          <br />
          <h3>C. Cookies, Matomo and File Downloads</h3>
          Consent to use cookies for statistical purposes:
          <br />
          <PrivacyToggle />
          <br />
          <h4>1. Cookies</h4>
          1.1 Cookies are stored on your computer each time you use our website.
          Cookies are small text files that are stored on your hard disk in the
          browser you use and through which certain information flows to the
          place that sets the cookie (in this case, us). Cookies cannot run
          programs or infect your computer. They do not cause any damage and
          render our website more user-friendly and efficient.
          <br />
          1.2 Our website uses the following types of cookies, whose scope and
          operation are explained hereinafter:
          <br />
          - Transient cookies (see Section 1.3 below); and/or
          <br />
          - Persistent cookies (see Section 1.4 below).
          <br />
          1.3 Transient cookies are deleted automatically when you close your
          browser. These include session cookies. These store a so-called
          session ID, with which different browser requests can be assigned to
          the common session. This allows your computer to be recognized each
          time you return to our website. Session cookies are deleted when you
          log out or close your browser.
          <br />
          1.4 Persistent cookies are automatically deleted after a specified
          duration, which may differ depending on the cookie. For us, this is 90
          days. You can delete the cookies at any time in your browser security
          settings.
          <br />
          1.5 The legal basis is consent according to Art. 6 Para. 1 lit. a of
          the GDPR and legitimate interest according to Art. 6 Para. 1 lit. f of
          the GDPR. For example, it is in our legitimate interest to improve our
          website and design it more user-friendly.
          <br />
          1.6 If you consent to the use of cookies, you may at any time revoke a
          consent given, by using the button above or object to the processing
          of your data using cookies. You can configure your browser settings
          according to your wishes and, for example, decline to accept third
          party cookies or all cookies. Please note that you may then not be
          able to use all functions of this website.
          <br />
          <h4>2. Matomo</h4>
          2.1 On our website we use Matomo (www.matomo.org) a self-hosted
          open-source-software to evaluate the usage of our website.
          <br />
          2.2 Matomo uses cookies (see Section 1. [Cookies]) which will be
          stored on your computer. The containing information regarding website
          and internet usage can be processed and evaluated by us.
          <br />
          2.3 Matomo can process the following data:
          <br />
          (a) Anonymized IP adress (the last byte will be deleted);
          <br />
          (b) Time in local user&apos;s timezone;
          <br />
          (c) Date and time of the request;
          <br />
          (d) User ID;
          <br />
          (e) Pseudonymized location with low accuracy;
          <br />
          (f) Title of the page being viewed;
          <br />
          (g) URL of the page being viewed;
          <br />
          (h) URL from which the request originates;
          <br />
          (i) Browser;
          <br />
          (j) Operating system and its interface;
          <br />
          (k) Screen resolution;
          <br />
          (l) Language and version of the browser software; <br />
          (m) Files that were clicked and downloaded; <br />
          (n) Links to an outside website that were clicked; and/or
          <br />
          (o) User agent of the browser being used.
          <br />
          2.4 The legal basis is legitimate interest according to Art. 6 Para. 1
          lit. f of the GDPR. For example, it is in our legitimate interest to
          improve our website usage.
          <br />
          2.5 We use your data exclusively for the improvement of our website.
          <br />
          <h4>3. File Downloads</h4>
          3.1 We do not require you to provide any personal information in order
          for you to download files from our website.
          <h3>D. External Links</h3>
          Our website contains links to other sites. We do not accept any
          liability for external links, notwithstanding careful content
          monitoring, as we have no control over the content and operation of
          third-party websites.
          <h3>E. Your Rights</h3>
          You have the following rights regarding your personal data:
          <br />
          <br />
          (a) Right to information;
          <br />
          <br />
          (b) Right to rectification or deletion;
          <br />
          <br />
          (c) Right to restriction of processing;
          <br />
          <br />
          (d) Right to object to processing; and
          <br />
          <br />
          (e) Right to data portability.
          <br />
          <br />
          If you have consented to the processing of your data, you can revoke
          this at any time. The revocation can be sent informally to the address
          of GASSER PARTNER Attorneys-at-Law, Feldkircher Strasse 31, Postfach 423, 9494 Schaan,
          Liechtenstein or by e-mail to datenschutz@gasserpartner.com.
          <br />
          To the extent that we seek to balance all interests in the processing
          of your personal data, you have the right to object to its processing.
          This is the case notably if processing is not required for the
          implementation of a contract with you, instances of which we have
          described hereinafter. When you file an objection, please explain why
          you do not wish us to process your personal data. If your opposition
          is justified, we will examine the situation and either cease or adjust
          the data processing or provide you with our overriding legitimate
          reasons for continuing to process the data.
          <br />
          If you believe that the processing of your data infringes existing
          data protection laws or violates your data protection rights in any
          way, you have the right to complain to the supervisory authority. For
          Liechtenstein, this is:
          <br />
          The Data Protection Agency,
          <br />
          Städtle 38,
          <br />
          9490 Vaduz, <br />
          Liechtenstein.
          <br />
          <h3>F. Data Security</h3>
          We have taken appropriate technical and organizational precautions to
          protect your data from unauthorized illegal or accidental access,
          processing, use, manipulation, loss or destruction.
          <br />
          Despite all our efforts, we cannot rule out the possibility that
          information you send us via the internet might be viewed and used by
          other people. Please note in particular that unencrypted e-mails sent
          over the internet are insufficiently protected against unauthorized
          access by third parties. We therefore recommend you send confidential
          information by post.
          <h3>G. Automated Decision Making</h3>
          We do not use fully- or semi-automated decision making. Our processing
          activities always depend on human intervention. <br />
          H. Current Version and Changes to the Privacy Policy
          <br />
          This Data Privacy Statement is the current version issued in February
          2021.
          <br />
          <br />
          In the event of any change in legislation or any service, product or
          user service, we will amend the Data Privacy Statement accordingly.
          Where the change also affects consent granted by you, changes will
          only be made after obtaining your prior consent.
        </SectionContent>
      </Section>
      <Section>
        <SectionHead
          className="section-head"
          onClick={() => toggleSection(1)}
          isActive={sections[1]}
        >
          <h2>
            Privacy Policy for Clients and Business Partners
            <br />
            GASSER PARTNER Attorneys-at-Law
          </h2>
          <SectionArrow isActive={sections[1]}>
            <ArrowDown />
          </SectionArrow>
        </SectionHead>
        <SectionContent className="section-content" isActive={sections[1]}>
          <h3>A. General</h3>
          This Data Protection Statement is to inform you about data processing
          if you enter into a business or attorney-client relationship with
          GASSER PARTNER. As a business partner or Client, you will receive in
          this Data Privacy Statement all the information you need as to how,
          why and the extent to which we collect and use personal data from you.
          It will also provide you with information on your rights and on data
          privacy.
          <h3>B. Collection, processing and use of personal data</h3>
          <h4>1. Which categories of personal data are collected?</h4>
          1.1. We may collect the following categories of personal data about
          you:
          <br />
          (a) your name and contact information such as your home and/or
          business address, e-mail address and telephone number;
          <br />
          (b) identity and biographical information including your nationality,
          date of birth, tax status, passport details and country of domicile,
          your current employment and employment history, plus other information
          relevant to our provision of professional services;
          <br />
          (c) information regarding your financial situation such as income,
          expenditure, assets and liabilities, sources of wealth, your bank
          account details and other information necessary for processing
          payments and for fraud prevention purposes;
          <br />
          (d) to a limited degree, usage data relating to your viewing and
          accessing of our e-mail marketing materials, and your marketing
          preferences (see Section C [Newsletter] below).
          <br />
          <h4>2. How is that personal data collected?</h4>
          2.1 We may collect your personal data, or you may provide it to us, by
          various means, from information:
          <br />
          (a) you provide to us when we meet;
          <br />
          (b) provided by our own organization, agents, advisers, intermediaries
          or custodians of your assets; <br />
          (c) communicated by you to us by telephone, post, e-mail or other
          forms of electronic communication;
          <br />
          (d) drawn from publicly available sources or from third parties, for
          example when we need to conduct background or due diligence checks
          (KYC) about you; <br />
          (e) collected when you view or access our website (see section C
          [Newsletters] below); and/or
          <br />
          (f) collected otherwise in the normal course of providing professional
          services.
          <br />
          <h4>
            3. What are the grounds for processing your personal data and how do
            we use that personal data?
          </h4>
          3.1 We use your personal data:
          <br />
          (a) to provide you with a proposal in relation to the professional
          services we offer and for client engagement purposes (including
          carrying out background checks);
          <br />
          (b) to provide you with professional services (including legal
          research and associated advisory services);
          <br />
          (c) to manage our relationship with you (including billing and
          financial management) and for record-keeping purposes;
          <br />
          (d) any other purpose for which you provide us with your personal
          data;
          <br />
          (e) the purposes set out in Section C (Newsletters) below;
          <br />
          (f) to exercise and defend our legal rights;
          <br />
          (g) to comply with our legal and regulatory obligations, such as the
          Due Diligence Act (DDA) and other anti-money-laundering laws, data
          protection laws and tax reporting requirements, and/or to assist with
          investigations by the police and/or competent authorities (where such
          an investigation complies with the relevant laws) and to comply with
          court orders;
          <br />
          (h) to safeguard the security of our systems and communications;
          and/or
          <br />
          (i) for security purposes in general, and to ensure the safety of our
          employees and visitors.
          <br />
          3.2 We may process your personal data for any of the purposes set out
          above where one (or more) of the following legitimate processing
          grounds applies:
          <br />
          (a) processing is necessary to implement a contract with you;
          <br />
          (b) processing is necessary for us to comply with our legal
          obligations;
          <br />
          (c) processing is necessary for our legitimate interests (including
          business development, if you have not objected to the use of your data
          - see Section C (Newsletters) below - unless those legitimate
          interests are overridden by your interests, fundamental rights or
          freedoms; and/or
          <br />
          (d) you have consented to the processing in question.
          <br />
          <h4>4. With whom may we share your data?</h4>
          4.1 We may share your personal data with:
          <br />
          (a) your organization;
          <br />
          (b) third parties we engage to assist in providing our professional
          services, such as lawyers, IT and accounting firms and other
          consultants, public relations advisers, translators and/or couriers;{' '}
          <br />
          (c) intermediaries to whom we introduce you;
          <br />
          (d) third party service providers who supply us with business
          services, such as the providers of anti-money-laundering services and
          background checks, for processing in accordance with our instructions;
          <br />
          (e) our banks and insurers, where appropriate;
          <br />
          (f) courts and other authorities, in connection with the enforcement
          of the defense of legal rights and the provision of our professional
          services.
          <h4>
            5. Will your data be transferred to third countries or to any
            international organization?
          </h4>
          5.1 Your personal data may be transferred to recipients (see Section
          4) in a third country.
          <br />
          5.2 The transfer of your personal data to recipients in a third
          country or an international organization will only take place if the
          following guarantees are present:
          <br />
          (a) According to the European Commission the third country offers an
          appropriate level of protection (adequacy decision); and/or
          <br />
          <br />
          (b) The recipient has signed a contract based on standard contractual
          clauses (SCCs) confirmed by the European Commission obliging it to
          protect personal data.
          <h4>6. How long will we retain your personal data?</h4>
          6.1. We will retain your personal data for as long as is necessary to
          fulfil the purposes set out in the Privacy Notice. <br />
          6.2. In many cases this will mean that we retain your personal data
          for the same period as we retain your files or a copy of your files.
          Usually this will not be less than 10 years from the date that the
          relevant matter ended.
          <br />
          6.3. Longer retention periods may be appropriate, for example to
          exercise or defend our legal rights. If there is no longer a legal
          reason for storing the data, we will delete personal data securely, or
          in some cases anonymize it.
          <br />
          <h3>C. Newsletters</h3>
          We may use your contact details to send you (by post or
          electronically) newsletters, event invitations and other mail
          promoting our services. We do this on the basis of our legitimate
          interests or your consent (as appropriate to the communication in
          question). You can always unsubscribe from these mailings by
          contacting us at datenschutz@gasserpartner.com.
          <br />
          <br />
          The legal basis is consent according to Art. 6 Para. 1 lit. a of the
          GDPR and legitimate interest according to Art. 6 Para. 1 lit. f of the
          GDPR. It is in our legitimate interest to inform you of changes to the
          law, current case law and our range of services.
          <h3>D. Your Rights</h3>
          You have the following rights regarding your personal data:
          <br />
          <br />
          (a) Right to information;
          <br />
          <br />
          (b) Right to rectification or deletion;
          <br />
          <br />
          (c) Right to restriction of processing;
          <br />
          <br />
          (d) Right to object to processing; and
          <br />
          <br />
          (e) Right to data portability.
          <br />
          <br />
          If you have consented to the processing of your data, you can revoke
          this at any time. The revocation can be sent informally to the address
          of GASSER PARTNER Attorneys-at-Law, Feldkircher Strasse 31, Postfach 423, 9494 Schaan,
          Liechtenstein or by e-mail to datenschutz@gasserpartner.com.
          <br />
          To the extent that we seek to balance all interests in the processing
          of your personal data, you have the right to object to its processing.
          This is the case notably if processing is not required for the
          implementation of a contract with you, instances of which we have
          described hereinafter. When you file an objection, please explain why
          you do not wish us to process your personal data. If your opposition
          is justified, we will examine the situation and either cease or adjust
          the data processing or provide you with our overriding legitimate
          reasons for continuing to process the data.
          <br />
          If you believe that the processing of your data infringes existing
          data protection laws or violates your data protection rights in any
          way, you have the right to complain to the supervisory authority. For
          Liechtenstein, this is:
          <br />
          The Data Protection Agency,
          <br />
          Städtle 38,
          <br />
          9490 Vaduz, <br />
          Liechtenstein.
          <h3>E. Data Security</h3>
          We have taken appropriate technical and organizational precautions to
          protect your data from unauthorized illegal or accidental access,
          processing, use, manipulation, loss or destruction.
          <br />
          Despite all our efforts, we cannot rule out the possibility that
          information you send us via the internet might be viewed and used by
          other people. Please note in particular that unencrypted e-mails sent
          over the internet are insufficiently protected against unauthorized
          access by third parties. We therefore recommend you send confidential
          information by post.
          <h3>F. Automated Decision Making</h3>
          We do not use fully- or semi-automated decision making. Our processing
          activities depend on human involvement.
          <h3>G. Current Version and Changes to the Privacy Policy</h3>
          This Data Privacy Statement is the current version issued in February
          2021.
          <br />
          <br />
          In the event of any change to legislation or a service, product or
          user service, we will amend the Data Privacy Statement accordingly.
          Where the amendment also concerns the consent granted by you, changes
          only take place after obtaining your prior consent.
        </SectionContent>
      </Section>
      <Section>
        <SectionHead
          className="section-head"
          onClick={() => toggleSection(2)}
          isActive={sections[2]}
        >
          <h2>
            Privacy Policy for Job Applicants
            <br />
            GASSER PARTNER Attorneys-at-Law
          </h2>
          <SectionArrow isActive={sections[2]}>
            <ArrowDown />
          </SectionArrow>
        </SectionHead>
        <SectionContent className="section-content" isActive={sections[2]}>
          <h3>A. General</h3>
          This Data Privacy Statement is to inform you how GASSER PARTNER
          processes your personal data when you apply for a job advertised by us
          or on spec. It will also provide you with information on your rights
          and on data security.
          <br />
          Should we enter into a service or employment relationship with you, we
          will continue to inform you in the context of the conclusion of the
          employment contract concerning the use of your data in the context of
          any employment relationship.
          <h3>B. Collection, processing and use of personal data</h3>
          <h4>1. Which categories of personal data are collected? </h4>
          1.1 We may collect the following categories of personal data about
          you:
          <br />
          (a) Name, age, gender, nationality, date of birth, place of birth,
          civil status, passport details, hobbies, private and/or business
          address, e-mail address and telephone number, current employment and
          employment history, roles, working hours, memberships and private
          work; <br />
          (b) Image data (application photograph);
          <br />
          (c) Data in the application cover letter;
          <br />
          (d) Data in certificates, educational certificates and references and
          evidence of employment; <br />
          (e) Information from the job interview such as salary expectations,
          type of employment desired, date of entry; <br />
          (f) Information from correspondence during the application process;
          <br />
          (g) Results from test processes; <br />
          (h) Data on references; and/or
          <br />
          (i) Further relevant information on testing or forming a service or
          employment relationship.
          <h4>2. How is that personal data collected?</h4>
          2.1 We may collect your personal data, or you may provide it to us, by
          various means, from information: <br />
          (a) you tell us at interview;
          <br />
          (b) you tell us in your written application;
          <br />
          (c) you tell us during a telephone call or by e-mail or in any other
          form of electronic communication; <br />
          (d) in the public domain or which has been communicated to us by third
          parties; and/or
          <br />
          (e) that we might otherwise learn when evaluating or justifying an
          employment or service relationship.
          <br />
          2.2 If you view job offers via our website and apply using our e-mail
          address, we may also collect other data. You can obtain further
          information on this through our Data Privacy Statement for website
          visitors.
          <h4>
            3. What are the grounds for processing your personal data and how do
            we use that personal data?
          </h4>
          3.1 We will use your personal data:
          <br />
          (a) to verify and justify an employment or service relationship;
          and/or
          <br />
          (b) to contact you on account of your application.
          <br />
          3.2 We may process your personal data for any of the purposes set out
          above where one (or more) of the following legitimate processing
          grounds applies:
          <br />
          (a) processing is necessary to implement a contract with you;
          <br />
          (b) processing is necessary for us to comply with our legal
          obligations;
          <br />
          (c) processing is necessary for our legitimate interests unless those
          legitimate interests are overridden by your interests, fundamental
          rights or freedoms; and/or
          <br />
          (d) you have consented to the processing in question.
          <h4>4. With whom may we share your data?</h4>
          4.1 Your personal data may be shared with:
          <br />
          (a) Internal offices other than those responsible for the application
          process which need the data in the context of the application process,
          for example team-leading senior partners and partners; and/or
          <br />
          (b) our IT provider, for the electronic storage of your application
          records
          <h4>
            5. Will your data be transferred to third counties or to an
            international organization?
          </h4>
          Your personal data in connection with your application will not be
          transferred to countries outside the European Economic Area or any
          international organizations.
          <h4>6. How long will we retain your personal data?</h4>
          After completion of the application process, we will store your
          personal data for a further two months in order to be able to defend,
          substantiate or assert any legal claims. Should we employ you, we will
          process and store your personal data for the term of your employment
          contract, in accordance with the general Data Privacy Statement.
          <h3>C. Your Rights</h3>
          You have the following rights regarding your personal data:
          <br />
          <br />
          (a) Right to information;
          <br />
          <br />
          (b) Right to rectification or deletion;
          <br />
          <br />
          (c) Right to restriction of processing;
          <br />
          <br />
          (d) Right to object to processing; and
          <br />
          <br />
          (e) Right to data portability.
          <br />
          <br />
          If you have consented to the processing of your data, you can revoke
          this at any time. The revocation can be sent informally to the address
          of GASSER PARTNER Attorneys-at-Law, Feldkircher Strasse 31, Postfach 423, 9494 Schaan,
          Liechtenstein or by e-mail to datenschutz@gasserpartner.com.
          <br />
          To the extent that we seek to balance all interests in the processing
          of your personal data, you have the right to object to its processing.
          This is the case notably if the processing is not required for the
          implementation of a contract with you, instances of which we have
          described hereinafter. When you file an objection, please explain why
          you do not wish us to process your personal data. If your opposition
          is justified, we will examine the situation and either cease or adjust
          the data processing or provide you with our overriding legitimate
          reasons for continuing to process the data.
          <br />
          If you believe that the processing of your data infringes existing
          data protection laws or violates your data protection rights in any
          way, you have the right to complain to the supervisory authority. For
          Liechtenstein, this is:
          <br />
          The Data Protection Agency,
          <br />
          Städtle 38,
          <br />
          9490 Vaduz, <br />
          Liechtenstein.
          <br />
          <h3>D. Data Security</h3>
          We have taken appropriate technical and organizational precautions to
          protect your data from unauthorized illegal or accidental access,
          processing, use, manipulation, loss or destruction.
          <br />
          Despite all our efforts, we cannot rule out the possibility that
          information you send us via the internet might be viewed and used by
          other people. Please note in particular that unencrypted e-mails sent
          over the internet are insufficiently protected against unauthorized
          access by third parties. We therefore recommend you send confidential
          information by post.
          <h3>E. Automated Decision Making</h3>
          We do not use fully- or semi-automated decision-making. Our processing
          activities always depend on human intervention.
          <h3>F. Current Version and Changes to the Privacy Policy</h3>
          The Data Privacy Statement is the current version issued in February
          2021.
          <br />
          <br />
          In the event of any change in the legal situation or any service,
          product or user service, we will amend the Data Privacy Statement
          accordingly. Where the amendment also affects the consent granted by
          you, amendments will only be made after obtaining your prior consent.
        </SectionContent>
      </Section>
    </Content>
  )
}

export default PrivacyPolicy
