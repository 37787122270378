import React from 'react'
import { useIntl } from 'react-intl'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Content from '../components/Content'
import Title from '../components/Title'
import Spacer from '../components/Spacer'
import Datenschutz from '../components/privacy/Datenschutz'
import PrivacyPolicy from '../components/privacy/PrivacyPolicy'

const DataProtectionPage = () => {
  const intl = useIntl()

  const currentLocale = intl.locale

  const title = currentLocale === 'de' ? 'Datenschutz' : 'Privacy Policy'

  return (
    <Layout>
      <SEO title={title} />
      <Content intro>
        <Title top="Gasser Partner">{title}</Title>
        {currentLocale === 'de' ? <Datenschutz /> : <PrivacyPolicy />}
        <Spacer size="small" />
      </Content>
    </Layout>
  )
}

export default DataProtectionPage

export const i18n = {
  en: '/privacy-policy',
  de: '/datenschutz',
}
