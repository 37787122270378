import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { shouldTrack, acceptConsent, declineConsent } from '../Consent/utils'
import theme from '../../theme'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const Toggle = styled.label`
  border: 1px solid #d8d8d8;
  background: #fff;
  position: relative;
  width: 62px;
  height: 34px;
  display: block;
  border-radius: 50px;
  z-index: 2;
  cursor: pointer;
  transition: color 0.3s ease;

  &::after {
    content: ' ';
    background-color: #d8d8d8;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    transform: translateX(0);
    transition: transform 0.3s ease;
    width: 28px;
    height: 28px;
    z-index: 3;
  }

  ${props =>
    props.active &&
    css`
      border-color: ${theme.colors.primary};

      &::after {
        transform: translateX(100%);
        background-color: ${theme.colors.primary};
      }
    `}
`

function PrivacyToggle() {
  const [active, setActive] = useState(false)

  function toggle() {
    if (active) {
      declineConsent()
      setActive(false)
      window.location.reload(false)
    } else {
      acceptConsent()
      setActive(true)
      window.location.reload(false)
    }
  }

  useEffect(() => {
    if (shouldTrack()) {
      setActive(true)
    }
  }, [])

  return (
    <Wrapper>
      <Toggle onClick={toggle} active={active} />
    </Wrapper>
  )
}

export default PrivacyToggle
