import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import ArrowDown from '../ArrowDown'
import PrivacyToggle from './PrivacyToggle'

const Content = styled.div`
  display: block;

  h2 {
    margin-bottom: 1.5rem;
    line-height: 1.2;
  }

  h3 {
    margin-bottom: 1rem;
    font-size: 1.3em;
  }
`

const Center = styled.div`
  margin: 0 auto;
  text-align: center;
`

const Section = styled.div`
  border-bottom: 1px solid #e63b28;

  &:last-child {
    border-bottom: none;
  }
`

const SectionHead = styled.div`
  cursor: pointer;
  position: relative;
  padding-right: 30px;
`

const SectionArrow = styled.div`
  position: absolute;
  top: 1rem;
  top: calc(50% - 17px);
  right: 0;
  pointer-events: none;
  transition: transform 0.25s ease;

  ${props =>
    props.isActive &&
    css`
      transform: rotate(-180deg);
    `}
`

const SectionContent = styled.div`
  display: none;
  padding-bottom: 4rem;

  ${props =>
    props.isActive &&
    css`
      display: block;
    `}
`

function Datenschutz() {
  const [sections, setSections] = useState([false, false, false])

  function toggleSection(index) {
    const newSections = [...sections]
    newSections[index] = !newSections[index]
    setSections(newSections)
  }

  return (
    <Content>
      <h2>
        Datenschutz
        <br /> bei GASSER PARTNER Rechtsanwälte
      </h2>
      <p>
        Der Schutz von personenbezogenen Daten geniesst bei GASSER PARTNER
        höchste Priorität. Aufgrund der anwaltlichen Verschwiegenheitspflicht
        sind wir in jedem Aspekt unserer Tätigkeit zu allerhöchster
        Geheimhaltung verpflichtet. Die Erhebung personenbezogener Daten erfolgt
        bei GASSER PARTNER im Einklang mit den gesetzlichen Bestimmungen der
        europäischen Datenschutzgrundverordnung (DSGVO) sowie den nationalen
        Gesetzen in aktueller Fassung.
      </p>
      <p>
        Hiermit möchten wir Sie gemäss Art. 13 DSGVO über die Erhebung und
        Verarbeitung von personenbezogenen Daten sowie die Ihnen nach der DSGVO
        zustehenden Rechte informieren.
      </p>
      <h3>Kontaktinformationen zum Verantwortlichen</h3>
      <p>
        GASSER PARTNER verarbeitet Informationen und personenbezogene Daten, die
        sich auf Sie beziehen. Verantwortlicher im Sinne der DSGVO ist:
      </p>
      <Center>
        <strong>GASSER PARTNER Rechtsanwälte</strong>
        <br />
        Feldkircher Strasse 31<br />
        Postfach 423<br />
        9494 Schaan
        <br />
        Liechtenstein
        <br />
        E-Mail: datenschutz@gasserpartner.com
        <br />
        Telefon: [+423] 236 30 80
      </Center>
      <br />
      <h3>Kontaktinformationen zum Datenschutzbeauftragten</h3>
      <p>
        Bei Fragen betreffend den Datenschutz bei GASSER PARTNER, nehmen Sie
        bitte Kontakt mit unserem Datenschutzbeauftragten auf:
      </p>
      <Center>
        <strong>GASSER PARTNER Rechtsanwälte</strong>
        <br />
        René Saurer
        <br />
        Feldkircher Strasse 31<br />
        Postfach 423<br />
        9494 Schaan
        <br />
        Liechtenstein
        <br />
        E-Mail: datenschutz@gasserpartner.com
        <br />
        Telefon: [+423] 236 30 80
      </Center>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <Section>
        <SectionHead
          className="section-head"
          onClick={() => toggleSection(0)}
          isActive={sections[0]}
        >
          <h2>
            Datenschutzerklärung für Webseitenbesucher
            <br />
            GASSER PARTNER Rechtsanwälte
          </h2>
          <SectionArrow isActive={sections[0]}>
            <ArrowDown />
          </SectionArrow>
        </SectionHead>
        <SectionContent className="section-content" isActive={sections[0]}>
          <h3>A. Allgemeines</h3>
          <p>
            Mit dieser Datenschutzerklärung möchten wir Sie über die
            Datenverarbeitung auf unserer Webseite (www.gasserpartner.com)
            informieren. Sie erhalten als Besucher unserer Webseite in dieser
            Datenschutzerklärung alle notwendigen Informationen darüber, wie, in
            welchem Umfang und zu welchem Zweck wir personenbezogene Daten von
            Ihnen erheben und diese verwenden. Zudem erhalten Sie Informationen
            zu Ihren Rechten und der Datensicherheit.
          </p>
          <h3>
            B. Erhebung, Verarbeitung und Verwendung von personenbezogenen Daten
          </h3>
          <h4>1. Welche Kategorien personenbezogener Daten werden erhoben?</h4>
          1.1 Beim blossen Aufruf unserer Webseite, also wenn Sie uns nicht
          anderweitig Informationen übermitteln, können wir die folgenden Daten
          erheben:
          <br />
          (a) IP-Adresse;
          <br />
          <br />
          (b) Datum und Uhrzeit der Anfrage;
          <br />
          <br />
          (c) Zeitzonenunterschied zur Greenwich-Zeit (GMT);
          <br />
          <br />
          (d) Inhalt der Anfrage (spezifische Seite);
          <br />
          <br />
          (e) Zugriffstatus/http-Status Code;
          <br />
          <br />
          (f) Übertragene Datenmenge bei jedem Besuch;
          <br />
          <br />
          (g) Webseite, von der aus die Anfrage ursprünglich gekommen ist;
          <br />
          <br />
          (h) Browser;
          <br />
          <br />
          (i) Betriebssystem und dessen Oberfläche; und/oder
          <br />
          <br />
          (j) Sprache und Version der Browser Software.
          <br />
          <br />
          1.2 Neben dem blossen Aufruf unserer Webseite, bieten wir Ihnen zudem
          die Möglichkeit, über E-Mail oder einer sonstigen elektronischen
          Nachricht mit uns in Kontakt zu treten. Hierzu können wir ausserdem
          folgende personenbezogenen Daten erfassen:
          <br />
          <br />
          (a) Name und Kontaktdaten;
          <br />
          <br />
          (b) Privat- und/oder Geschäftsadresse; und/oder
          <br />
          <br />
          (c) E-Mail-Adresse und Telefonnummer.
          <br />
          <br />
          <h4>2. Wie werden Ihre personenbezogenen Daten erhoben?</h4>
          2.1 Personenbezogene Daten werden von uns selbst erhoben oder durch
          unterschiedliche Kanäle von Ihnen an uns mitgeteilt, ausgehend von
          Informationen, die:
          <br />
          (a) wir durch das Aufrufen und Betrachten unserer Webseite erhalten
          (vgl. Punkt C [Cookies, Matomo und Dateidownloads]); und/oder
          <br />
          (b) Sie uns per E-Mail oder auf eine andere Form der elektronischen
          Kommunikation mitteilen.
          <br />
          <br />
          <h4>
            3. Auf welcher Grundlage basiert die Verarbeitung Ihrer
            personenbezogenen Daten und wie werden diese Daten verwendet?
          </h4>
          3.1 Die Verarbeitung Ihrer personenbezogenen Daten beschränkt sich
          grundsätzlich auf jene Daten, die zur Bereitstellung einer
          funktionsfähigen Webseite sowie unserer Inhalte und Leistungen
          erforderlich sind. <br />
          3.2 Wir verwenden Ihre personenbezogenen Daten (um):
          <br />
          (a) Ihnen unsere Webseite anzuzeigen;
          <br />
          (b) die Stabilität und Sicherheit unserer Webseite zu gewährleisten;
          und/oder
          <br />
          (c) Ihre E-Mail Anfrage zu erledigen.
          <br />
          3.3 Wir verarbeiten Ihre personenbezogenen Daten aus obengenannten
          Gründen, sofern einer (oder mehrere) der nachfolgenden berechtigten
          Gründe zutreffen:
          <br />
          (a) die Verarbeitung ist für die Erfüllung unseres Vertrages
          notwendig;
          <br />
          (b) die Verarbeitung ist notwendig, damit wir unseren rechtlichen
          Verpflichtungen nachkommen können;
          <br />
          (c) die Verarbeitung ist zur Wahrung unserer berechtigten Interessen
          notwendig, ausser Ihre berechtigten Interessen überwiegen den unseren,
          bspw. durch ein Grundrecht; und/oder
          <br />
          (d) eine ausdrückliche Einwilligung Ihrerseits zur Verwendung der
          Daten liegt vor.
          <br />
          <br />
          <h4>
            4. Mit wem können Ihre personenbezogenen Daten geteilt werden?
          </h4>
          4.1 Ihre personenbezogenen Daten können geteilt werden mit:
          <br />
          (a) Dritten, die uns technisch unterstützen, wie unseren IT
          Beauftragten; und/oder
          <br />
          (b) Drittanbietern, die uns helfen unsere Webseite ansprechender und
          funktionsfähiger zu gestalten (siehe unten Punkt C [Cookies, Matomo
          und Dateidownloads]).
          <br />
          <br />
          <h4>
            5. Werden Ihre Daten in Drittländer oder an eine internationale
            Organisation übermittelt?
          </h4>
          5.1 Im Falle Ihres Besuchs auf unserer Webseite oder mittels
          Kontaktaufnahme über unsere Webseite können Ihre personenbezogenen
          Daten an Empfänger (siehe Punkt 4) in einem Drittland übermittelt
          werden.
          <br />
          5.2 Eine Übermittlung Ihrer personenbezogenen Daten an Empfänger in
          einem Drittland oder eine internationale Organisation findet nur dann
          statt, wenn folgende Garantien vorliegen:
          <br />
          (a) das Drittland bietet gemäss der Europäischen Kommission ein
          angemessenes Schutzniveau (Angemessenheitsbeschluss); und/oder
          <br />
          (b) der Empfänger hat einen Vertrag basierend auf von der Europäischen
          Kommission bestätigten Standardvertragsklauseln (SCC) unterzeichnet,
          die ihn zum Schutz der personenbezogenen Daten verpflichtet. <br />
          5.3 Eine Übertragung Ihrer personenbezogenen Daten ausserhalb des
          Europäischen Wirtschaftsraumes erfolgt jedenfalls in die Schweiz. Die
          Europäische Kommission hat mittels Angemessenheitsbeschluss ein
          ausreichendes Schutzniveau festgestellt.
          <br />
          <br />
          <h4>6. Wie lange werden Ihre personenbezogenen Daten gespeichert?</h4>
          6.1 Ihre personenbezogenen Daten speichern wir aus Gründen der
          Datensicherheit, um die Stabilität und die Betriebssicherheit unseres
          Systems zu gewährleisten und nur solange dies zur Erfüllung der
          genannten Zwecke notwendig ist.
          <br />
          6.2 Bei der Kontaktaufnahme via E-Mail speichert unser IT Beauftragter
          Ihre personenbezogenen Daten. Falls es zu einem Geschäfts- bzw.
          Mandatsverhältnis kommt, werden wir Ihre personenbezogenen Daten für
          die Dauer Ihres Geschäfts- bzw. Mandatsverhältnisses entsprechend der
          allgemeinen Datenschutzerklärung verarbeiten und speichern.
          <br />
          6.3 Eine Fehlermeldung auf der Hauptseite wird vom Hosting Provider
          für die Dauer von 7 Tagen in ‘error logs’ mit dem Datum, der
          IP-Adresse, der Seite und der Art des Fehlers gespeichert.
          <br />
          <br />
          <h3>C. Cookies, Matomo und Dateidownloads</h3>
          Zustimmung zum Gebrauch der Cookies zu statistischen Zwecken:
          <br />
          <PrivacyToggle />
          <br />
          <h4>1. Cookies</h4>
          1.1 Bei der Nutzung unserer Homepage werden Cookies auf Ihrem Rechner
          gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die
          auf Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet
          gespeichert werden und durch welche der Stelle, die den Cookie setzt
          (hier durch uns), bestimmte Informationen zufliessen. Cookies können
          keine Programme ausführen oder Viren auf Ihren Computer übertragen.
          Sie richten keinen Schaden an, sondern dienen dazu, unser
          Internetangebot nutzerfreundlicher und effektiver zu machen.
          <br />
          <br />
          1.2 Unsere Homepage nutzt folgende Arten von Cookies, deren Umfang und
          Funktionsweise im Folgenden erläutert werden:
          <br />
          - Transiente Cookies (siehe dazu Punkt 1.3); und/oder
          <br />
          - Persistente Cookies (siehe dazu Punkt 1.4).
          <br />
          <br />
          1.3 Transiente Cookies werden automatisiert gelöscht, wenn Sie den
          Browser schliessen. Dazu zählen insbesondere die Session-Cookies.
          Diese speichern eine sogenannte Session-ID, mit welcher sich
          verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen
          lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf
          unsere Homepage zurückkehren. Die Session-Cookies werden gelöscht,
          wenn Sie sich ausloggen oder den Browser schliessen.
          <br />
          <br />
          1.4 Persistente Cookies werden automatisiert nach einer vorgegebenen
          Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Bei uns
          beträgt diese Dauer 24 Tage. Sie können die Cookies in den
          Sicherheitseinstellungen Ihres Browsers jederzeit löschen. <br />
          <br />
          1.5 Rechtsgrundlage ist die Einwilligung nach Art. 6 Abs. 1 lit. a
          DSGVO und das berechtigte Interesse gemäss Art. 6 Abs. 1 lit. f DSGVO.
          Unser berechtigtes Interesse liegt zum Beispiel darin, unser
          Internetangebot nutzerfreundlicher zu gestalten und es zu verbessern.
          <br />
          <br />
          1.6 Wenn Sie Ihre Einwilligung gegeben haben, können Sie diese
          jederzeit widerrufen, indem Sie den obigen Button verwenden oder der
          Verarbeitung der Daten durch Cookies widersprechen. Dazu können Sie
          Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und
          z.B. die Annahme von Third-Party-Cookies oder allen Cookies ablehnen.
          Wir weisen Sie darauf hin, dass Sie dann eventuell nicht alle
          Funktionen dieser Homepage nutzen können.
          <br />
          <br />
          <h4>2. Matomo</h4>
          2.1 Auf unserer Webseite wird Matomo (www.matomo.org), eine
          selbstgehostete Open-Source-Software zur Auswertung der
          Webseitenbenutzung eingesetzt.
          <br />
          <br />
          2.2 Matomo verwendet Cookies (vgl. Punkt 1 [Cookies]), die auf Ihrem
          Computer gespeichert werden. Die darin enthaltenen Informationen über
          die Webseite- und Internetnutzung des Besuchers können von uns
          verarbeitet und ausgewertet werden.
          <br />
          <br />
          2.3 Matomo kann die folgenden Daten verarbeiten:
          <br />
          (a) Anonymisierte IP-Adresse (das letzte byte wird entfernt);
          <br />
          (b) Zeit in der Zeitzone des Benutzers;
          <br />
          (c) Datum und Uhrzeit der Anfrage;
          <br />
          (d) User ID;
          <br />
          (e) Pseudonymisierter Standort mit niedriger Genauigkeit;
          <br />
          (f) Name der aufgerufenen Seite;
          <br />
          (g) URL der aufgerufenen Seite;
          <br />
          (h) URL von der Seite aus der die Anfrage ursprünglich gekommen ist;
          <br />
          (i) Browser;
          <br />
          (j) Betriebssystem und dessen Oberfläche;
          <br />
          (k) Bildschirmauflösung;
          <br />
          (l) Sprache und Version der Browser Software; <br />
          (m) Dateien, die angeklickt und heruntergeladen wurden; <br />
          (n) Links zu externen Webseiten, die angeklickt wurden; und/oder
          <br />
          (o) User Agent des verwendeten Browsers.
          <br />
          <br />
          2.4 Rechtsgrundlage ist das berechtigte Interesse nach Art. 6 Abs. 1
          lit. f DSGVO. Unser berechtigtes Interesse liegt zum Beispiel darin,
          die Webseitenbenutzung zu optimieren.
          <br />
          <br />
          2.5 Wir verwenden Ihre Daten ausschliesslich zur Verbesserung unseres
          Internetangebotes.
          <br />
          <br />
          <h4>3. Dateidownloads</h4>
          3.1 Wir verlangen von Ihnen keine persönlichen Angaben, damit Sie
          Dateien von unserer Internetseite herunterladen können.
          <br />
          <h3>D. Externe Links</h3>
          Unsere Webseite und diese Datenschutzerklärung enthalten Links zu
          anderen Seiten. Wir übernehmen keine Haftung für externe Links.
          Ungeachtet der sorgfältigen Überwachung und Kontrolle, haben wir keine
          Kontrolle über den Inhalt und den Betrieb dieser verlinkten Webseiten.
          <br />
          <h3>E. Ihre Rechte</h3>
          Ihnen stehen gegenüber uns nachfolgende Rechte bezüglich Ihrer
          personenbezogenen Daten zu:
          <br />
          <br />
          (a) Recht auf Auskunft;
          <br />
          <br />
          (b) Recht auf Berichtigung oder Löschung;
          <br />
          <br />
          (c) Recht auf Einschränkung der Verarbeitung;
          <br />
          <br />
          (d) Recht auf Widerspruch der Verarbeitung; und
          <br />
          <br />
          (e) Recht auf Datenübertragbarkeit.
          <br />
          <br />
          Sofern Sie der Verarbeitung Ihrer Daten zugestimmt haben, können Sie
          diese Einwilligung jederzeit widerrufen. Der Widerruf kann formlos an
          die Adresse von GASSER PARTNER Rechtsanwälte, Feldkircher Strasse 31, Postfach 423, 9494 Schaan, Liechtenstein oder per E-Mail an
          datenschutz@gasserpartner.com erfolgen.
          <br />
          <br />
          Bei der Verarbeitung Ihrer personenbezogenen Daten versuchen wir alle
          Interessen zu berücksichtigen, dennoch haben Sie das Recht der
          Verarbeitung zu widersprechen. Das ist insbesondere dann der Fall,
          wenn die Verarbeitung für die Erfüllung des Vertrages mit Ihnen nicht
          erforderlich ist. Sofern Sie der Verarbeitung widersprechen, bitten
          wir Sie uns den Grund Ihres Widerrufs mitzuteilen. Ist Ihr Widerruf
          gerechtfertigt, werden wir die Situation prüfen und entweder die
          Datenverarbeitung beenden, anpassen oder Ihnen die rechtmässigen
          Gründe mitteilen, aufgrund welcher wir die Datenverarbeitung
          fortsetzen.
          <br />
          <br />
          Wenn Sie der Meinung sind, dass unsere Datenverarbeitung gegen
          geltendes Datenschutzrecht verstösst oder Ihr persönliches Recht auf
          Datenschutz in irgendeiner Weise verletzt wird, haben Sie das Recht,
          sich an die zuständige Behörde zu wenden. In Liechtenstein ist dies:
          <br />
          <br />
          Datenschutzstelle Fürstentum Liechtenstein
          <br />
          Städtle 38,
          <br />
          9490 Vaduz, <br />
          Liechtenstein.
          <br />
          <h3>F. Datensicherheit</h3>
          Wir haben entsprechende technische und organisatorische Massnahmen zum
          Schutz Ihrer personenbezogenen Daten vor unrechtmässigem oder
          versehentlichem Lesen, Verarbeiten, Gebrauch, Fälschung, Verlust oder
          Zerstörung getroffen.
          <br />
          <br />
          Trotz all unserer Anstrengung, können wir jedoch nicht ausschliessen,
          dass die Informationen, die Sie uns digital zukommen lassen durch
          andere Personen gelesen oder verwendet werden. Bitte beachten Sie,
          dass unverschlüsselte E-Mails, welche Sie per Internet verschicken
          nicht ausreichend gegen den unrechtmässigen Zugang Dritter geschützt
          sind. Daher empfehlen wir Ihnen, vertrauliche Informationen mit der
          Post an uns zu übermitteln.
          <br />
          <h3>G. Automatisierte Entscheidungen</h3>
          Eine voll- oder teilautomatisierte Entscheidungsfindung wird bei uns
          nicht eingesetzt. Unsere Verarbeitungstätigkeiten setzen stets das
          Einwirken einer Person voraus. <br />
          <h3>H. Aktuelle Fassung und Änderungen der Datenschutzerklärung</h3>
          Diese Datenschutzerklärung ist die aktuell geltende Fassung mit Stand
          Februar 2021.
          <br />
          <br />
          Im Fall einer Änderung der Rechtslage bzw. einer Dienstleistung, eines
          Produkts oder eines Benutzerdienstes, werden wir die
          Datenschutzerklärung entsprechend anpassen. Sofern die Änderung auch
          die von Ihnen erteilte Einwilligung betrifft, erfolgen Änderungen nur
          nach Ihrer vorherigen Zustimmung.
        </SectionContent>
      </Section>
      <Section>
        <SectionHead
          className="section-head"
          onClick={() => toggleSection(1)}
          isActive={sections[1]}
        >
          <h2>
            Datenschutzerklärung für Mandanten und Geschäftspartner
            <br />
            GASSER PARTNER Rechtsanwälte
          </h2>
          <SectionArrow isActive={sections[1]}>
            <ArrowDown />
          </SectionArrow>
        </SectionHead>
        <SectionContent className="section-content" isActive={sections[1]}>
          <h3>A. Allgemeines</h3>
          Mit dieser Datenschutzerklärung möchten wir Sie über die
          Datenverarbeitung informieren, wenn Sie ein Geschäfts- bzw.
          Mandantenverhältnis mit GASSER PARTNER eingehen. Sie erhalten als
          Geschäftspartner oder Mandant in dieser Datenschutzerklärung alle
          notwendigen Informationen darüber, wie, in welchem Umfang und zu
          welchem Zweck wir personenbezogene Daten von Ihnen erheben und diese
          verwenden. Zudem erhalten Sie Informationen zu Ihren Rechten und der
          Datensicherheit.
          <br />
          <h3>
            B. Erhebung, Verarbeitung und Verwendung von personenbezogenen Daten
          </h3>
          <h4>1. Welche Kategorien personenbezogener Daten werden erhoben?</h4>
          1.1 Folgende Kategorien personenbezogener Daten können von uns über
          Sie erhoben werden:
          <br />
          (a) Name und Kontaktdaten sowie Privat- und/oder Geschäftsadresse,
          E-Mail-Adresse und Telefonnummer;
          <br />
          (b) Identität und biografische Informationen einschliesslich
          Nationalität, Geburtsdatum, Steuererklärungsdaten, Passdaten und
          Wohnsitz, aktuelle und ehemalige Arbeitsstelle(n) sowie weitere
          relevante Informationen zur Erfüllung unserer Beratungstätigkeit;
          <br />
          (c) Informationen betreffend die Vermögenslage wie beispielsweise
          Einkommen, Ausgaben, Vermögen und Schulden, Informationen betreffend
          der Herkunft des Vermögens, des Bankkontos und weitere für die
          Bearbeitung der Zahlung sowie der Betrugsbekämpfung notwendige
          Informationen;
          <br />
          (d) in begrenztem Umfang Nutzungsdaten, welche wir durch das Aufrufen
          und den Zugriff auf unser Werbematerial erhalten sowie Ihre
          Marketingpräferenzen (vgl. Punkt C [Newsletter]).
          <br />
          <h4>2. Wie werden Ihre personenbezogenen Daten erhoben?</h4>
          2.1 Personenbezogene Daten werden von uns selbst erhoben oder durch
          unterschiedliche Kanäle von Ihnen an uns mitgeteilt, ausgehend von
          Informationen, die:
          <br />
          (a) Sie uns bei einem Treffen mitteilen;
          <br />
          (b) Sie uns durch Ihre Organisation, Ihren Vertreter, Ihren Berater,
          einen Mittelsmann oder durch Ihre Depotbank, mitteilen; <br />
          (c) Sie uns bei einem Telefongespräch, per E-Mail oder auf eine andere
          Form der elektronischen Kommunikation mitteilen;
          <br />
          (d) öffentlich zugänglich sind oder uns durch Dritte mitgeteilt
          werden, beispielsweise wenn wir einen Background- oder
          Sorgfaltspflicht-Check vornehmen;
          <br />
          (e) wir durch das Aufrufen und Betrachten unserer Webseite erhalten
          (vgl. Punkt C [Newsletter]); und/oder
          <br />
          (f) wir auf andere Weise bei der Wahrnehmung unserer
          Beratungstätigkeit zur Kenntnis nehmen.
          <br />
          <h4>
            3. Auf welcher Grundlage basiert die Verarbeitung Ihrer
            personenbezogenen Daten und wie werden diese Daten verwendet?
          </h4>
          3.1 Wir verwenden Ihre personenbezogenen Daten (um):
          <br />
          (a) Ihnen ein Angebot in Zusammenhang mit unserer Beratungstätigkeit
          zu unterbreiten (einschliesslich der Durchführung von
          Background-Checks);
          <br />
          (b) unserer Beratungstätigkeit nachzukommen (einschliesslich
          juristischer Recherchen und den damit zusammenhängenden
          Beratungstätigkeiten);
          <br />
          (c) das Geschäfts- oder Mandatsverhältnis zu verwalten
          (einschliesslich der Rechnungsstellung und der Vermögensverwaltung)
          und aus Dokumentationsgründen; sowie
          <br />
          (d) für sämtliche anderen Gründe, für die Sie uns personenbezogene
          Daten mitteilen;
          <br />
          (e) für die in Punkt C enthaltenen Gründe (Newsletter);
          <br />
          (f) zur Ausübung und Verteidigung unserer gesetzmässigen Rechte;
          <br />
          (g) zur Einhaltung gesetzlicher und regulatorischer Pflichten wie den
          Sorgfaltspflichten nach dem SPG bzw. SPV und anderen zur Bekämpfung
          von Geldwäscherei und Terrorismusfinanzierung dienenden Gesetze,
          Datenschutzgesetze(n), steuerrechtlichen Anzeigepflichten und/oder der
          Unterstützung von Ermittlungen durch die Polizei und/oder einer
          anderen dafür zuständige Behörde (bei welcher eine solche Ermittlung
          durch entsprechende Gesetze gerechtfertigt ist) sowie um gerichtlichen
          Aufforderungen nachzukommen;
          <br />
          (h) zur Gewährleistung der Sicherheit unseres Systems und
          Kommunikation; und/oder
          <br />
          (i) aus allgemeinen Sicherheitsgründen und zur Gewährleistung der
          Sicherheit unserer Mitarbeiter und Besucher. <br />
          3.2 Wir verarbeiten Ihre personenbezogenen Daten aus obengenannten
          Gründen, sofern einer (oder mehrere) der nachfolgenden berechtigten
          Gründe zutreffen:
          <br />
          (a) die Verarbeitung ist für die Erfüllung unseres Vertrages
          notwendig;
          <br />
          (b) die Verarbeitung ist notwendig, damit wir unseren rechtlichen
          Verpflichtungen nachkommen können;
          <br />
          (c) die Verarbeitung ist zur Wahrung unserer berechtigten Interessen
          notwendig (einschliesslich Business Development, sofern sie der
          Verwendung Ihrer Daten zu Zwecken wie diesen nicht widersprochen haben
          –vgl. Punkt C [Newsletter]), ausser Ihre berechtigten Interessen
          überwiegen den unseren, bspw. durch ein Grundrecht; und/oder
          <br />
          (d) eine ausdrückliche Einwilligung Ihrerseits zur Verwendung der
          Daten liegt vor.
          <br />
          <h4>
            4. Mit wem können Ihre personenbezogenen Daten geteilt werden?
          </h4>
          4.1 Ihre personenbezogenen Daten können geteilt werden mit:
          <br />
          (a) Ihrer Organisation;
          <br />
          (b) Dritten, die uns bei unserer Beratungstätigkeit unterstützen, wie
          beispielsweise Rechtsanwälte, IT Beauftragte und andere Berater,
          PR-Beratern, Übersetzer und/oder Boten;
          <br />
          (c) Intermediäre, die wir Ihnen vorstellen;
          <br />
          (d) Drittanbieter, die Geschäftsbeziehungen mit uns pflegen sowie
          Dienstleistungsanbieter betreffend Dienstleistungen zur
          Geldwäschereibekämpfung und zu Background-Checks, die
          Datenverarbeitungsprozesse für uns durchführen, Banken und
          Versicherungen; und/oder
          <br />
          (f) den Gerichten und anderen Behörden im Zusammenhang mit der
          Wahrnehmung der Verteidigung von gesetzlichen Rechten und Erbringung
          unserer Beratungstätigkeiten.
          <br />
          <h4>
            5. Werden Ihre Daten in Drittländer oder an eine internationale
            Organisation übermittelt?
          </h4>
          5.1 Ihre personenbezogenen Daten können an Empfänger (siehe Punkt 4)
          in einem Drittland übermittelt werden.
          <br />
          5.2 Eine Übermittlung Ihrer personenbezogenen Daten an Empfänger in
          einem Drittland oder eine internationale Organisation findet nur dann
          statt, wenn folgende Garantien vorliegen:
          <br />
          (a) das Drittland bietet gemäss Europäischen Kommission ein
          angemessenes Schutzniveau (Angemessenheitsbeschluss); und/oder
          <br />
          (b) der Empfänger hat einen Vertrag basierend auf von der Europäischen
          Kommission bestätigten Standardvertragsklauseln (SCCs) unterzeichnet,
          die ihn zum Schutz der personenbezogenen Daten verpflichtet. <br />
          <h4>6. Wie lange werden Ihre personenbezogenen Daten gespeichert?</h4>
          6.1. Ihre personenbezogenen Daten werden so lange wie notwendig
          gespeichert, um den in dieser Datenschutzerklärung definierten Zwecken
          nachzukommen.
          <br />
          6.2. In einigen Fällen bedeutet das, dass wir Ihre personenbezogenen
          Daten für den gleich langen Zeitraum speichern müssen, wie Ihre
          Dokumente oder eine Kopie Ihrer Dokumente. Normalerweise handelt es
          sich dabei um einen Zeitraum von nicht mehr als 10 Jahren ab dem Ende
          des relevanten Sachverhalts bzw. Mandatierung der Rechtsberatung.
          <br />
          6.3. Die Speicherung Ihrer Daten für einen längeren als den
          obengenannten Zeitraum kann angemessen sein, z.B. für die Ausübung
          oder Verteidigung unserer gesetzlichen Rechte. Liegt kein gesetzlicher
          Grund für die Speicherung mehr vor, werden wir Ihre personenbezogenen
          Daten löschen oder, in manchen Fällen, anonymisieren.
          <br />
          <h3>C. Newsletter</h3>
          Wenn Sie ein Geschäfts- bzw. Mandatsverhältnis mit uns haben,
          verwenden wir Ihre Kontaktdaten um Ihnen (per Post oder elektronisch)
          unseren Newsletter, Veranstaltungseinladungen, unser Weihnachtsmailing
          und andere per Mail an Sie gerichtete Dienstleistungsangebote zukommen
          zu lassen. Sie können sich jedoch jederzeit ohne Nennung von Gründen
          vom Newsletter abmelden, in dem Sie uns dies durch E-Mail an
          datenschutz@gasserpartner.com mitteilen.
          <br />
          <br />
          Rechtsgrundlage ist die Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO
          und das berechtigte Interesse gemäss Art. 6 Abs. 1 lit. f DSGVO. Unser
          berechtigtes Interesse liegt darin, Sie etwa über Gesetzesänderungen,
          aktuelle Rechtsprechung und unsere Dienstleistungsangebote zu
          informieren.
          <br />
          <h3>D. Ihre Rechte</h3>
          Ihnen stehen gegenüber uns nachfolgende Rechte bezüglich Ihrer
          personenbezogenen Daten zu:
          <br />
          <br />
          (a) Recht auf Auskunft;
          <br />
          <br />
          (b) Recht auf Berichtigung oder Löschung;
          <br />
          <br />
          (c) Recht auf Einschränkung der Verarbeitung;
          <br />
          <br />
          (d) Recht auf Widerspruch der Verarbeitung; und/oder
          <br />
          <br />
          (e) Recht auf Datenübertragbarkeit.
          <br />
          <br />
          Sofern Sie der Verarbeitung Ihrer Daten zugestimmt haben, können Sie
          diese Einwilligung jederzeit widerrufen. Der Widerruf kann formlos an
          die Adresse von GASSER PARTNER Rechtsanwälte, Feldkircher Strasse 31, Postfach 423, 9494 Schaan, Liechtenstein oder per E-Mail an
          datenschutz@gasserpartner.com erfolgen.
          <br />
          <br />
          Bei der Verarbeitung Ihrer personenbezogenen Daten versuchen wir alle
          Interessen zu berücksichtigen, dennoch haben Sie das Recht der
          Verarbeitung zu widersprechen. Das ist insbesondere dann der Fall,
          wenn die Verarbeitung für die Erfüllung des Vertrages mit Ihnen nicht
          erforderlich ist. Sofern Sie der Verarbeitung widersprechen, bitten
          wir Sie uns den Grund Ihres Widerrufs mitzuteilen. Ist Ihr Widerruf
          gerechtfertigt, werden wir die Situation prüfen und entweder die
          Datenverarbeitung beenden, anpassen oder Ihnen die rechtmässigen
          Gründe mitteilen, aufgrund welcher wir die Datenverarbeitung
          fortsetzen.
          <br />
          <br />
          Wenn Sie der Meinung sind, dass unsere Datenverarbeitung gegen
          geltendes Datenschutzrecht verstösst oder Ihr persönliches Recht auf
          Datenschutz in irgendeiner Weise verletzt wird, haben Sie das Recht,
          sich an die zuständige Behörde zu wenden. In Liechtenstein ist dies:
          <br />
          <br />
          Datenschutzstelle Fürstentum Liechtenstein
          <br />
          Städtle 38,
          <br />
          9490 Vaduz, <br />
          Liechtenstein.
          <br />
          <h3>E. Datensicherheit</h3>
          Wir haben entsprechende technische und organisatorische Massnahmen zum
          Schutz Ihrer personenbezogenen Daten vor unrechtmässigem oder
          versehentlichem Lesen, Verarbeiten, Gebrauch, Fälschung, Verlust oder
          Zerstörung getroffen.
          <br />
          <br />
          Trotz all unserer Anstrengung, können wir jedoch nicht ausschliessen,
          dass die Informationen, die Sie uns digital zukommen lassen durch
          andere Personen gelesen oder verwendet werden. Bitte beachten Sie,
          dass unverschlüsselte E-Mails, welche Sie per Internet verschicken
          nicht ausreichend gegen den unrechtmässigen Zugang Dritter geschützt
          sind. Daher empfehlen wir Ihnen, vertrauliche Informationen mit der
          Post an uns zu übermitteln.
          <br />
          <h3>F. Automatisierte Entscheidungen</h3>
          Eine voll- oder teilautomatisierte Entscheidungsfindung wird bei uns
          nicht eingesetzt. Unsere Verarbeitungstätigkeiten setzen stets das
          Einwirken einer Person voraus. <br />
          <h3>G. Aktuelle Fassung und Änderungen der Datenschutzerklärung</h3>
          Diese Datenschutzerklärung ist die aktuell geltende Fassung mit Stand
          Februar 2021.
          <br />
          <br />
          Im Fall einer Änderung der Rechtslage bzw. einer Dienstleistung, eines
          Produkts oder eines Benutzerdienstes, werden wir die
          Datenschutzerklärung entsprechend anpassen. Sofern die Änderung auch
          die von Ihnen erteilte Einwilligung betrifft, erfolgen Änderungen nur
          nach Ihrer vorherigen Zustimmung. Zudem erhalten Sie Informationen zu
          Ihren Rechten und der Datensicherheit.
        </SectionContent>
      </Section>
      <Section>
        <SectionHead
          className="section-head"
          onClick={() => toggleSection(2)}
          isActive={sections[2]}
        >
          <h2>
            Datenschutzerklärung für Bewerber
            <br />
            GASSER PARTNER Rechtsanwälte
          </h2>
          <SectionArrow isActive={sections[2]}>
            <ArrowDown />
          </SectionArrow>
        </SectionHead>
        <SectionContent className="section-content" isActive={sections[2]}>
          <h3>A. Allgemeines</h3>
          Diese Datenschutzerklärung informiert Sie darüber, wie GASSER PARTNER
          Ihre persönlichen Daten verarbeitet, wenn Sie sich für eine von uns
          ausgeschriebene Stelle oder initiativ bewerben. Zudem erhalten Sie
          Informationen zu Ihren Rechten und der Datensicherheit.
          <br />
          Sollten wir mit Ihnen ein Arbeits- bzw. Dienstverhältnis begründen,
          werden wir Sie im Rahmen des Abschlusses des Arbeitsvertrags
          weitergehend über die Verwendung Ihrer Daten im Rahmen eines
          Beschäftigungsverhältnisses informieren.
          <br />
          <h3>
            B. Erhebung, Verarbeitung und Verwendung von personenbezogenen Daten
          </h3>
          <h4>1. Welche Kategorien personenbezogener Daten werden erhoben? </h4>
          1.1 Folgende Kategorien personenbezogener Daten könnten von uns über
          Sie erhoben werden:
          <br />
          (a) Name, Alter, Geschlecht, Nationalität, Geburtsdatum und -ort,
          Zivilstand, Passdaten, Hobbies, Privat- und/oder Geschäftsadresse,
          E-Mail-Adresse und Telefonnummer, aktuelle und ehemalige
          Arbeitsstelle(n), Funktionen, Arbeitszeiten, Mitgliedschaften und
          ausserdienstlichen Mandaten; <br />
          (b) Bilddaten (Bewerbungsfoto);
          <br />
          (c) Daten im Bewerbungsanschreiben;
          <br />
          (d) Daten in Zeugnisse, Ausbildungsnachweise sowie Arbeitszeugnisse
          und -bestätigungen; <br />
          (e) Informationen aus dem Bewerbungsgespräch wie Gehaltsvorstellung,
          Art der gewünschten Beschäftigung, Eintrittsdatum; <br />
          (f) Informationen aus Korrespondenzen während des Bewerbungsprozesses;
          <br />
          (g) Ergebnisse aus Test-Verfahren; <br />
          (h) Daten zu Referenzen; und/oder
          <br />
          (i) Weitere relevante Informationen zur Prüfung bzw. Begründung eines
          Arbeits- bzw. Dienstverhältnisses. <br />
          <h4>2. Wie werden Ihre personenbezogenen Daten erhoben?</h4>
          2.1 Personenbezogene Daten werden von uns selbst erhoben oder durch
          unterschiedliche Kanäle von Ihnen an uns mitgeteilt, ausgehend von
          Informationen, die: <br />
          (a) Sie uns im Bewerbungsgespräch mitteilen;
          <br />
          (b) Sie uns in Ihrer brieflichen Bewerbung mitteilen;
          <br />
          (c) Sie uns bei einem Telefongespräch, per E-Mail oder auf eine andere
          Form der elektronischen Kommunikation mitteilen; <br />
          (d) öffentlich zugänglich sind oder uns durch Dritte mitgeteilt
          werden; und/oder
          <br />
          (e) wir auf andere Weise bei der Wahrnehmung zur Prüfung bzw.
          Begründung eines Arbeits- bzw. Dienstverhältnisses zur Kenntnis
          nehmen.
          <br />
          2.2 Wenn Sie Stellenangebote über unsere Webseite einsehen und sich
          über unsere E-Mail-Adresse bewerben, können wir ausserdem weitere
          Daten erheben. Weitere Informationen hierzu erhalten Sie über unsere
          Datenschutzerklärung für Webseitenbesucher.
          <h4>
            3. Auf welcher Grundlage basiert die Verarbeitung Ihrer
            personenbezogenen Daten und wie werden diese Daten verwendet?
          </h4>
          3.1 Wir verwenden Ihre personenbezogenen Daten (um):
          <br />
          (a) Arbeits- bzw. Dienstverhältnis zu prüfen und begründen; und/oder
          <br />
          (b) Sie aufgrund Ihrer Bewerbung zu kontaktieren.
          <br />
          3.2 Wir verarbeiten Ihre personenbezogenen Daten aus obengenannten
          Gründen, sofern einer (oder mehrere) der nachfolgenden berechtigten
          Gründe zutreffen:
          <br />
          (a) die Verarbeitung ist für die Erfüllung unseres Vertrages
          notwendig;
          <br />
          (b) die Verarbeitung ist notwendig, damit wir unseren rechtlichen
          Verpflichtungen nachkommen können;
          <br />
          (c) die Verarbeitung ist zur Wahrung unserer berechtigten Interessen
          notwendig, ausser Ihre berechtigten Interessen überwiegen den unseren,
          beispielsweise durch ein Grundrecht; und/oder
          <br />
          (d) eine ausdrückliche Einwilligung Ihrerseits zur Verwendung der
          Daten liegt vor.
          <h4>
            4. Mit wem können Ihre personenbezogenen Daten geteilt werden?
          </h4>
          4.1 Ihre personenbezogenen Daten können geteilt werden mit:
          <br />
          (a) Anderen als für den Bewerbungsprozess zuständigen internen
          Stellen, welche die Daten im Rahmen des Bewerbungsprozesses benötigen,
          beispielsweise teamleitende Senior Partner und Partner; und/oder
          <br />
          (b) unserem IT Beauftragten, zur elektronischen Speicherung Ihrer
          Bewerbungsunterlagen.
          <h4>
            5. Werden Ihre Daten in Drittländer oder an eine internationale
            Organisation übermittelt?
          </h4>
          Es erfolgt keine Übermittlung Ihrer personenbezogenen Daten im
          Zusammenhang mit Ihrer Bewerbung in Länder ausserhalb des Europäischen
          Wirtschaftsraumes oder an internationale Organisationen.
          <h4>6. Wie lange werden Ihre personenbezogenen Daten gespeichert?</h4>
          Nach Abschluss des Bewerbungsprozesses werden wir Ihre
          personenbezogenen Daten weitere zwei Monate speichern, um etwaige
          Rechtsansprüche abwehren, begründen oder geltend machen zu können.
          Falls es zu einer Anstellung kommt, werden wir Ihre personenbezogenen
          Daten für die Dauer Ihres Arbeitsverhältnisses entsprechend der
          allgemeinen Datenschutzerklärung verarbeiten und speichern.
          <h3>C. Ihre Rechte</h3>
          Ihnen stehen gegenüber uns nachfolgende Rechte bezüglich Ihrer
          personenbezogenen Daten zu:
          <br />
          <br />
          (a) Recht auf Auskunft;
          <br />
          <br />
          (b) Recht auf Berichtigung oder Löschung;
          <br />
          <br />
          (c) Recht auf Einschränkung der Verarbeitung;
          <br />
          <br />
          (d) Recht auf Widerspruch der Verarbeitung; und/oder
          <br />
          <br />
          (e) Recht auf Datenübertragbarkeit.
          <br />
          <br />
          Sofern Sie der Verarbeitung Ihrer Daten zugestimmt haben, können Sie
          diese Einwilligung jederzeit widerrufen. Der Widerruf kann formlos an
          die Adresse von GASSER PARTNER Rechtsanwälte, Feldkircher Strasse 31, Postfach 423, 9494 Schaan, Liechtenstein oder per E-Mail an
          datenschutz@gasserpartner.com erfolgen.
          <br />
          <br />
          Bei der Verarbeitung Ihrer personenbezogenen Daten versuchen wir alle
          Interessen zu berücksichtigen, dennoch haben Sie das Recht der
          Verarbeitung zu widersprechen. Das ist insbesondere dann der Fall,
          wenn die Verarbeitung für die Erfüllung des Vertrages mit Ihnen nicht
          erforderlich ist. Sofern Sie der Verarbeitung widersprechen, bitten
          wir Sie uns den Grund Ihres Widerrufs mitzuteilen. Ist Ihr Widerruf
          gerechtfertigt, werden wir die Situation prüfen und entweder die
          Datenverarbeitung beenden, anpassen oder Ihnen die rechtmässigen
          Gründe mitteilen, aufgrund welcher wir die Datenverarbeitung
          fortsetzen.
          <br />
          <br />
          Wenn Sie der Meinung sind, dass unsere Datenverarbeitung gegen
          geltendes Datenschutzrecht verstösst oder Ihr persönliches Recht auf
          Datenschutz in irgendeiner Weise verletzt wird, haben Sie das Recht,
          sich an die zuständige Behörde zu wenden. In Liechtenstein ist dies:
          <br />
          <br />
          Datenschutzstelle Fürstentum Liechtenstein
          <br />
          Städtle 38,
          <br />
          9490 Vaduz, <br />
          Liechtenstein.
          <br />
          <br />
          <h3>D. Datensicherheit</h3>
          Wir haben entsprechende technische und organisatorische Massnahmen zum
          Schutz Ihrer personenbezogenen Daten vor unrechtmässigem oder
          versehentlichem Lesen, Verarbeiten, Gebrauch, Fälschung, Verlust oder
          Zerstörung getroffen.
          <br />
          <br />
          Trotz all unserer Anstrengung, können wir jedoch nicht ausschliessen,
          dass die Informationen, die Sie uns digital zukommen lassen durch
          andere Personen gelesen oder verwendet werden. Bitte beachten Sie,
          dass unverschlüsselte E-Mails, welche Sie per Internet verschicken
          nicht ausreichend gegen den unrechtmässigen Zugang Dritter geschützt
          sind. Daher empfehlen wir Ihnen, vertrauliche Informationen mit der
          Post an uns zu übermitteln.
          <h3>E. Automatisierte Entscheidungen</h3>
          Eine voll- oder teilautomatisierte Entscheidungsfindung wird bei uns
          nicht eingesetzt. Unsere Verarbeitungstätigkeiten setzen stets das
          Einwirken einer Person voraus. <br />
          <h3>F. Aktuelle Fassung und Änderungen der Datenschutzerklärung</h3>
          Diese Datenschutzerklärung ist die aktuell geltende Fassung mit Stand
          Februar 2021.
          <br />
          <br />
          Im Fall einer Änderung der Rechtslage bzw. einer Dienstleistung, eines
          Produkts oder eines Benutzerdienstes, werden wir die
          Datenschutzerklärung entsprechend anpassen. Sofern die Änderung auch
          die von Ihnen erteilte Einwilligung betrifft, erfolgen Änderungen nur
          nach Ihrer vorherigen Zustimmung.
        </SectionContent>
      </Section>
    </Content>
  )
}

export default Datenschutz
